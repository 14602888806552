<template>
  <div class="article-box" v-loading="loading">
    <!-- 导航 -->
    <div class="page-nav clearfix">
      <ul>
        <li class="nav-item">会员管理&nbsp;&nbsp;/</li>
        <li class="nav-item nav-item-last">&nbsp;&nbsp;个人会员管理</li>
      </ul>
    </div>
    <!-- 标题 -->
    <div class="pag-title clearfix">
      <div class="page-title-name pull-left">个人会员管理</div>
    </div>
    <!-- 搜索栏 -->
    <div class="search-box clearfix">
      <!-- <div class="search-label pull-left">名称或账号:</div> -->
      <div class="search-item pull-left">
        <el-input
          v-model="keyword"
          clearable
          size="small"
          style="width: 200px; height: 32px"
          placeholder="请输入姓名或账号"
        >
        </el-input>
      </div>
      <div class="button-item button-search pull-left" @click="searchHandle">
        查询
      </div>
      <div class="button-item button-down pull-right" @click="handleExport">
        导出会员
      </div>
    </div>
    <el-table border :data="listData" highlight-current-row header-row-class-name="table-header-class" :header-cell-style="{ 'background-color': '#F7F8FA' }"
    style="width: 100%; margin-top: 20px;" >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="loginName" label="账号（手机号）" ></el-table-column>
      <el-table-column prop="userNickName" label="姓名" ></el-table-column>
      <el-table-column prop="unitName" label="单位" ></el-table-column>
      <el-table-column prop="position" label="职务" ></el-table-column>
      <el-table-column prop="technicalTitles" label="职称" ></el-table-column>
      <el-table-column prop="email" label="邮箱" ></el-table-column>
      <el-table-column prop="" label="操作" width="200">
        <template slot-scope="scope" >
          <div class="clecrfix">
            <div class="option-item pull-left option-normal" @click="getDetail(scope.row.id)" >
              详情
            </div>
            <div class="option-item pull-left option-err" @click="removeInitiation(scope.row.id)" >
              移出机构
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: right; padding-top: 20px"
      @size-change="pageSizeHandle"
      @current-change="pageChangeHandle"
      :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="pageSize"
      :current-page="pageNo"
      :page-count="totalPage"
      :total="totalCount"
    >
    </el-pagination>
    <!-- 表格 -->
    <!-- <div class="table-box" v-if="totalCount">
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th></th>
            <th></th>
            <th>单位</th>
            <th>职务</th>
            <th>职称</th>
            <th>邮箱</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listData" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.loginName || "-" }}</td>
            <td>{{ item.userNickName || "-" }}</td>
            <td>{{ item.unitName || "-" }}</td>
            <td>{{ item.position || "-" }}</td>
            <td>{{ item.position || "-" }}</td>
            <td>{{ item.email || "-" }}</td>
            <td class="clecrfix">
              <div
                class="option-item pull-left option-normal"
                @click="getDetail(item.id)"
              >
                详情
              </div>
              <div
                class="option-item pull-left option-err"
                @click="removeInitiation(item.id)"
              >
                移出机构
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <!-- <div class="no-data-img" v-if="noData"></div> -->
    <!-- 移除 -->
    <el-dialog title="移出机构" :visible.sync="dialogVisible" width="30%">
      <p style="margin: 20px">确定将该会员单位移出机构？</p>
      <div style="margin: 20px" class="clearfix">
        <div
          class="button-item button-search pull-right"
          @click="sureRemove()"
          style="margin-left: 20px"
        >
          确定
        </div>
        <div
          class="button-item button-cancle pull-right"
          @click="dialogVisible = false"
        >
          取消
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { FileDownload2 } from "@/http/api/FileApi";

import {
  InstitutionPersonalMemberList,
  getFromExport,
  InstitutionPersonalMemberDel,
} from "@/http/api/InstitutionApi";
export default {
  components: {},
  data() {
    return {
      listData: [],
      keyword: "",
      noData: false,
      totalPage: 0,
      totalCount: 0,
      pageNo: 1,
      pageSize: 10,
      loading: false, //页面加载
      dialogVisible: false,
      delId: "",
      state: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {

    //导出
    handleExport() {
        FileDownload2("/member/i/org/member/person/export",{
            institutionsId :this.state,//: 参与分支机构id ,
            keyword :this.keyword,//: 用户名或账号
        });
    },

    getList() {
      this.loading = true;
      var item = {};
      item.type = 2;
      item.state = 2;
      item.keyword = this.keyword || "";
      item.pageNo = this.pageNo || 1;
      item.pageSize = this.pageSize || 10;
      InstitutionPersonalMemberList(item).then((resp) => {
        this.loading = false;
        if (resp.code === "000") {
          this.totalPage = resp.result.pages;
          this.totalCount = resp.result.total;
          this.pageNo = resp.result.current;
          this.listData = resp.result.records;
          if (!resp.result.total) {
            this.noData = true;
          }
        } else {
          this.$Message.error("获取数据失败");
        }
      });
    },
    searchHandle() {
      this.pageNo = 1;
      this.getList();
    },
    // 分页函数
    pageSizeHandle(val) {
      this.pageNo = 1;
      this.pageSize = val;
      this.getList();
    },
    pageChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    getDetail(id) {
      this.$router.push({
        path: "/institution/member/personal/detail",
        query: {
          id: id,
        },
      });
    },
    removeInitiation(id) {
      this.delId = id;
      this.dialogVisible = true;
    },
    sureRemove() {
      InstitutionPersonalMemberDel({ id: this.delId }).then((resp) => {
        if (resp.code == "000") {
          this.dialogVisible = false;
          this.getList();
        } else {
          this.$Message.error("操作失败");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.no-data-img {
  width: 100%;
  height: 300px;
  background: url("../../assets/images/nodata.png") no-repeat center center;
}
/deep/.el-dialog__body {
  padding: 5px 20px;
}
.first-tab ul {
  width: 100%;
}
.first-tab ul li {
  display: inline-block;
  width: 50%;
  line-height: 32px;
  .datail-label {
    display: inline-block;
    width: 40%;
    text-align: right;
    padding-right: 10px;
  }
  .datail-text {
    display: inline-block;
    width: 60%;
    text-align: left;
  }
}
.first-tab ul .one-line {
  width: 100%;
  .datail-label {
    width: 20%;
  }
  .datail-text {
    width: 80%;
  }
}
.first-tab ul .one-third-line {
  width: 33%;
  .datail-label {
    width: 40%;
  }
  .datail-text {
    width: 60%;
  }
}
.datail-title {
  font-size: 16px;
  font-weight: 700;
  margin: 20px;
}
</style>
